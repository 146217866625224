<template>
  <div id="app">
    <CV />
  </div>
</template>

<script>
import CV from './components/CV.vue'

export default {
  name: 'App',
  components: {
    CV
  }
}
</script>

<style>
body {
  background-color: #eee !important;
}
</style>
