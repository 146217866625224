import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// import 'material-design-icons/iconfont/material-icons.css'

import './css/main.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import "@fontsource/raleway/latin.css"
import "@fontsource/lato/latin.css"

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
