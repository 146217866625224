<template>
  <div>
    <div class="d-flex justify-content-between align-items-baseline">
      <div class="competency-name">{{c.name}} <span>{{c.extra}}</span></div>
      <div class="competency-score">{{c.amount}} / 10</div>
    </div>
    <div class="d-flex justify-content-between">
      <span v-for="x in 10" :key="x">
        <span class="material-icons star" v-if="x <= c.amount">star</span>
        <span class="material-icons star" v-else>star_outline</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'c' ]
}
</script>

<style lang="scss" scoped>
.competency-name {
  font-size: 87.5%;
  font-weight: bold;
  margin-bottom: 0.45em;

  span {
    font-weight: normal;
  }
}

.competency-score {
  font-family: Lato;
  font-size: 0.8rem;
}

.star {
  font-size: 1.4rem;
  color: rgb(241, 102, 94);
}
</style>
