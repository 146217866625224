<template>
  <div class="wrapper">
    <div class="container bg-white">
      <div class="row shadow">
        <div class="col-lg-4">
          <div class="row vcard">
            <div class="col">
              <div class="text-center">
                <div class="user-thumb">
                  <div class="image"></div>
                  <div class="overlay"></div>
                </div>

                <div class="my-name mt-4">
                  <div>Tom Wesch</div>
                  <div style=" font-size: 18px; "><small>
                    Software Developer<br>
                    Data Engineer<br>
                    Architect and Tech Lead<br>
                  </small></div>
                </div>
              </div>

              <div class="info ml-4">
                <div class="mt-5"><span class="material-icons material-icon-header text-white">home</span> <a href="https://www.google.com/maps/place/27389+Stemmen" target="_blank" class="text-white">27389 Stemmen</a></div>
                <div class="mt-2"><span class="material-icons material-icon-header text-white">mail</span> <a class="text-white" href="mailto:tom@wesch.io">tom@wesch.io</a></div>
                <div class="mt-2"><span class="material-icons material-icon-header text-white">contact_page</span> <a class="text-white" href="https://tom.wesch.io">tom.wesch.io</a></div>
              </div>
            </div>
          </div>

          <div class="left-col">
            <div class="mb-5">
              <h1>
                <span class="indent">
                  <span class="material-icons material-icon-header" >person_outline</span> Summary
                </span>
              </h1>

              <p>Hello there,<span class="material-icons" style="font-size: 1.3em; margin-left: 4px; vertical-align: sub;"> emoji_people </span></p>
              <p>I am happy to see that you are interested in me and the projects / work that I did so far.</p>
              <p>When I was starting in a small startup in 2008, I would have never anticipated that this company will grow into a leader of the mobile games industry. In my journey of <strong>more than 13 years</strong>, I shifted from working on the first game titles to <strong>building centralized solutions</strong> which are part of all games. These solutions are essential for analytics, CRM, marketing, business intelligence and finance. I took part in the development of most backend systems at InnoGames and also started many of them.</p>
<!-- “Near-time CRM” -->
                <p>
                  Formal feedbacks that I received in the last years describe me as <strong class="light"><cite>“problem solver”</cite></strong>, <strong class="light"><cite>“consistent high performer”</cite></strong>, <strong class="light"><cite>“overachiever”</cite></strong>, <strong class="light"><cite>“loyal”</cite></strong>, <strong class="light"><cite>“good mentor”</cite></strong>, <strong class="light"><cite>“passionate”</cite></strong>, <strong class="light"><cite>“enabling”</cite></strong> and <strong class="light"><cite>“autonomous”</cite></strong>.
                </p>

                <!-- <p>Weaknesses: Smalltalk, have to </p> -->

            </div>

            <div class="mb-5">
              <h1>
                <span class="indent">
                  <span class="material-icons material-icon-header">donut_small</span> Core Competencies
                </span>
              </h1>

              <div style="max-width: 340px">
                <div v-for="(c, i) in competencies" :key="i" class="mb-3">
                  <Stars :c="c"/>
                </div>
              </div>
            </div>

            <div class="mb-5">
              <h1>
                <span class="indent">
                  <span class="material-icons material-icon-header">interests</span> Interests
                </span>
              </h1>

              <div class="interests">
                <ul>
                  <li><strong>Family</strong>: Our son was born in 2020, our daughter in 2022 <span class="material-icons" style="font-size: 122%; vertical-align: middle; margin-bottom: 2px;"> child_care </span></li>
                  <li><strong>Photography</strong> (DSLR): landscape photography, shooting on weddings <span class="material-icons" style="font-size: 122%; vertical-align: middle; margin-bottom: 2px;"> photo_camera_back </span></li>
                  <li><strong>Microelectronics</strong>: building circuits and development on 8 and 16-bit microcontroller architectures in Assembler and C, mostly PIC RISC CPUs; C implementation of TCP/IP stack; Raspberry Pi applications<!-- ; playing with LEDs / light is fun --> <span class="material-icons" style="font-size: 122%; vertical-align: middle; margin-bottom: 2px;"> memory </span></li>
                  <li><strong>Travelling</strong>: Visited the United States, Netherlands, Belgium, France, Greece, Ireland, Poland, Spain, Indonesia&nbsp;(Bali), Morocco <span class="material-icons" style="font-size: 122%; vertical-align: middle; margin-bottom: 2px;"> flight_takeoff </span></li>
                  <li><strong>Piano</strong>: Started taking lessons together with my <span class="text-nowrap">wife in 2018 <span class="material-icons" style="font-size: 122%; vertical-align: middle; margin-bottom: 2px;"> piano </span><span class="material-icons" style="font-size: 122%; vertical-align: middle; margin-bottom: 2px;"> music_note </span></span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col right-col">
          <div class="mb-5">
            <h1>
              <span class="indent">
                <span class="material-icons material-icon-header"> work_outline </span>
                Work Experience
              </span>
            </h1>

            <div style="margin-bottom: 2.5rem;">
              <h6 :title="timeSince(new Date('2023-05-01'), new Date())">February 2023 – present</h6>
              <div class="d-flex justify-content-between align-items-baseline overflow-auto">
                <h2>Solution Architect</h2>
                <h3 style="margin-left: 20px;" class="text-nowrap">For Sale Digital GmbH</h3>
              </div>

              <div class="article-content mt-2">
                <i>… lots of cool things to come!</i>
              </div>
            </div>

            <div style="margin-bottom: 2.5rem;">
              <h6 :title="timeSince(new Date('2022-05-01'), new Date('2023-03-01'))">May 2022 – February 2023</h6>
              <div class="d-flex justify-content-between align-items-baseline overflow-auto">
                <h2>Software &amp; Data Engineer / Architect</h2>
                <h3 style="margin-left: 20px;" class="text-nowrap">Fabulous</h3>
              </div>

              <div class="article-content mt-2">
                <ul>
                  <li>
                    Extending and maintaining the Fabulous backend that is based on <b>Google Cloud Platform (GCP)</b> and <b>Firebase</b>. The backend is used by <a href="https://apps.apple.com/us/app/fabulous-daily-habit-tracker/id1203637303" target="_blank">iOS</a>/<a href="https://play.google.com/store/apps/details?id=co.thefabulous.app" target="_blank">Android</a> apps and the <a href="https://thefabulous.co/" target="_blank">web version of Fabulous</a>. <b-badge>Node.js</b-badge><b-badge>GCP</b-badge> <b-badge>Firebase</b-badge>
                  </li>
                  <li>
                    <!-- Journey Personalization -->
                    Implementation of a project to <b>personalize content</b> that is delivered to users. The content is fully customizable via JavaScript logic that incorporates their state.
                    The project is built to allow future ML/AI use cases.
                  </li>
                  <li>
                    Implementation of event data ingestion into Google BigQuery. High volume events that are too much to handle in <a
href="https://amplitude.com/" target="_blank">Amplitude</a> are sent from the mobile apps to the backend, which then kicks off processing via Pub/Sub. Data is encoded using Protobuf schema and transferred in an optimized binary format. <b-badge>BigQuery</b-badge>
                  </li>
                  <li>
                    Adjusting the backend to support multiple apps after the company has made the strategical decision to kick off the development of the second app: <a href="https://apps.apple.com/gb/app/shape-healthy-eating-journal/id1629419955" target="_blank">Shape</a>.
                  </li>
                </ul>
              </div>
            </div>

            <div style="margin-bottom: 2.5rem;">
              <h6 :title="timeSince(new Date('2021-01-01'), new Date('2022-05-01'))">January 2021 – April 2022</h6>
              <div class="d-flex justify-content-between align-items-baseline overflow-auto">
                <h2>Senior Software Developer <small class="text-nowrap" style="font-size:0.9rem; margin-left: 4px;">(Architect, Conversion)</small></h2>
                <h3 style="margin-left: 20px;" class="text-nowrap">InnoGames GmbH</h3>
              </div>

              <div class="article-content mt-2">
                <ul>
                  <li>
                    <p>Joined the team to lead a new project to revamp the Landing Page System (LPS) on a technical and functional level. The system is dated and has grown into being very complex, has many repositories, big codebases and became fragile. The main goal of this project is to <strong>bring the landing page development back on track to improve the profitability of marketing activities</strong>.</p>

                    <p>State of the art frontend technologies and development workflows should be possible with a new Design Bundle concept that focuses on autonomy, versioning and immutability of landing pages. The quality and stability of the system itself and the landing pages should be significantly improved. This is achieved by using a modular approach where components will be deployed individually. It is planned to <strong>scale the system to hundreds of instances</strong>.

                    Furthermore, the A/B testing capabilities, reporting, analysis, monitoring, security and delivery performance will get an update. <b-badge>Java</b-badge> <b-badge>TypeScript</b-badge> <b-badge>React (JS)</b-badge>
                    </p>

                    The vision of the project is to utilize <strong>machine learning and AI</strong> to fully <strong>automate routing and A/B testing</strong>. The system will test new capabilities of landing pages automatically and display the best possible pages based on user demographics. This routing will be much more fine-grained and is flexible whenever conditions change. <b-badge>Reinforcement Learning</b-badge>

                    <!-- “client side tracking” -->
                  </li>
                  <li>Maintenance and feature development on various projects that are the backbone of the player/account infrastructure. This includes the current landing page systems, central player API, single sign on (via partners) and the player deletion self-service. Support for the games to implement necessary features and APIs (like Facebook and Apple privacy changes). <b-badge>Java</b-badge> <b-badge>PHP</b-badge> <b-badge>MySQL</b-badge></li>
                  <li>Technical mentoring of <strong>16 career starters</strong> (apprentices, dual bachelor and master students) from different departments and teams with regular training sessions, workshops and pair programming. <b-badge variant="success">Mentoring</b-badge></li>
                </ul>
              </div>
            </div>

            <div style="margin-bottom: 2.5rem;">
              <h6 :title="timeSince(new Date('2015-03-01'), new Date('2021-01-01'))">March 2015 – December 2020</h6>
              <div class="d-flex justify-content-between align-items-baseline overflow-auto">
                <h2>Senior Software Developer <small class="text-nowrap" style="font-size:0.9rem; margin-left: 4px;">(Data Engineering, Analytics)</small></h2>
                <h3 style="margin-left: 20px;" class="text-nowrap">InnoGames GmbH</h3>
              </div>

              <div class="article-content mt-2">
                <ul>
                  <li>Extension of the <strong>event tracking</strong> system to allow sending events in a JSON format. The system processes 300 million events daily in 2015 and was scaled to process <strong>1.2 billion daily events</strong> in 2019. The latency when events can be accessed was reduced to <strong >one second</strong> on average. Events are defined with <a href="https://json-schema.org/" target="_blank">JSON Schema</a> to define what they need to contain and to ensure that they are sent correctly. This resolved all data quality issues and improved usability a lot. Stakeholders were enabled to define event schema on their own. The Hadoop system has a <strong>capacity of 6.8&nbsp;petabytes</strong> by now. <b-badge>Apache Hadoop</b-badge> <b-badge>JSON Schema</b-badge></li>
                  <li>The ingestion into Hadoop which was done by <a href="https://storm.apache.org/" target="_blank">Apache Storm</a> was replaced with Apache Flink to simplify the setup and to get the needed performance. <b-badge>Apache Flink</b-badge></li>
                  <li>Implementation of a Go daemon as a replacement for the event client to improve handling of the events on game side. This daemon is running on thousands of servers. <b-badge>Go</b-badge></li>
                  <li>Hadoop event storage and performance optimizations by using <a href="https://parquet.apache.org/" target="_blank">Apache Parquet</a> and <a href="https://orc.apache.org/" target="_blank">Apache ORC</a>. <b-badge>Apache Parquet</b-badge> <b-badge>Apache ORC</b-badge></li>
                  <li>Introduction of <a href="https://airflow.apache.org/" target="_blank">Apache Airflow</a> as ETL scheduler to model job dependencies using DAGs. This replaces the previous Jenkins solution, which didn't scale anymore with more than <strong>400 ETL jobs</strong>. <b-badge>Apache Airflow</b-badge> <b-badge>Apache ORC</b-badge></li>
                  <li>Implementation of the “<strong>Player Profile</strong>”, a wide table that contains <strong>all players and all sorts of attributes</strong> that are relevant for BI and CRM. As part of it, the “Contact History” was built. These are tables with very detailed information about when we contacted players and how they reacted. <b-badge>Apache Hadoop</b-badge> <b-badge>Apache Hive</b-badge> <b-badge>Apache Sqoop</b-badge> <b-badge>Python</b-badge> <b-badge>Bash</b-badge></li>
                  <li>System design and implementation of a “<strong>Near-time CRM</strong>” system to target user behavior with CRM measures. The system uses event tracking data, which is very detailed at this point. <b-badge>Java</b-badge> <b-badge>Apache Kafka</b-badge> <b-badge>Redis</b-badge> <b-badge>Apache Cassandra</b-badge></li>
                  <li>System design and implementation of a <strong>high-performance CRM solution</strong> to replace <a href="https://www.dymatrix.de/loesungen/marketing-automation" target="_blank">DynaCampaign</a> (setup by consultants from DYMATRIX). Part of the new system is a custom distributed database (SelectionDB / PlayerDB) that is built to improve flexibility and performance. The new system combines batch processing and event streaming (<strong>lambda architecture</strong>) to keep the player data up to date in real-time. Players can be selected with a JavaScript DSL that is implemented with a <strong>high-performance runtime in the JVM</strong>. The nightly import of the player profile (~200 million players, 50 columns) just takes a couple of minutes. Selections take 5 seconds on average. The database <strong>clearly outperformed the previous solutions</strong> and is now the backbone for all CRM operations. End users can use the database with a UI that gives insights about the state and allows testing of selections. This frontend is also used to investigate player state and behavior for various reasons. The monetary impact of the CRM operations is quite huge nowadays. <b-badge>Java</b-badge> <b-badge>Protocol Buffers</b-badge> <b-badge>gRPC</b-badge> <b-badge>Spring Framework</b-badge> <b-badge>Vue.js</b-badge>
                  <li>Technical advice for removal of personal information in various systems in context of the new <a href="https://gdpr-info.eu/" target="_blank">GDPR</a> laws. Implementation of a deletion process to delete inactive accounts automatically after two years. <b-badge>Python</b-badge> <b-badge>GDPR</b-badge></li>
                  <li>Implementation of a real-time registration/login ratio monitoring with <a href="https://nightlies.apache.org/flink/flink-docs-stable/docs/libs/cep/" target="_blank">FlinkCEP</a> and <a href="https://grafana.com/" target="_blank">Grafana</a>. <b-badge>Apache Flink</b-badge> <b-badge>Grafana</b-badge></li>
                  <li>Mentoring of a trainee in the team for the entire duration of the apprenticeship. Technical mentoring of trainees in other teams. <b-badge variant="success">Mentoring</b-badge></li>
                  <!-- <li>Technical mentoring of junior, regular and senior developers. <b-badge variant="success">Mentoring</b-badge></li> -->

<!-- In-House Campaign Management Solution (DynaCampaign replacement), -->
<!-- Player Profile, Contact History, -->
<!-- Near Time CRM, Redis, Apache Cassandra (PP Push), -->
<!-- SelectionDB / PlayerDB, Lambda Architecture, -->

<!-- App Annie scrape, -->
<!-- Seeded Neartime A/B Tests, -->
<!-- PlayerDB Frontend, -->
<!-- GDPR, user rights, personal data, data exports, deletion of inactive users, -->
 <!-- real time log00 monitoring with FlinkCEP and Grafana, -->
<!-- Consulting CRM Team, -->
<!-- Hendrik Mentoring, -->
<!-- Career Starters -->

                </ul>
              </div>
            </div>

            <div style="margin-bottom: 2.5rem;">
              <h6 :title="timeSince(new Date('2013-09-01'), new Date('2015-03-01'))">September 2013 – February 2015</h6>
              <div class="d-flex justify-content-between align-items-baseline overflow-auto">
                <h2>Software Developer <small class="text-nowrap" style="font-size:0.9rem; margin-left: 4px;">(Data Engineering, Analytics)</small></h2>
                <h3 style="margin-left: 20px;" class="text-nowrap">InnoGames GmbH</h3>
              </div>

              <div class="article-content mt-2">
                <ul>
                  <li>Update of the event tracking system by introducing <a href="https://storm.apache.org/" target="_blank">Apache Storm</a> to scale the event ingestion part of the system. <b-badge>Java</b-badge> <b-badge>Apache Hadoop</b-badge> <b-badge>Apache Kafka</b-badge> <b-badge>Apache Storm</b-badge></li>
                  <li>Implementation of a process to calculate user playtime and sessions based on event data with <a href="https://spark.apache.org/" target="_blank">Apache Spark</a>. <b-badge>Java</b-badge> <b-badge>Apache Spark</b-badge></li>
                  <li>Implementation of a MapReduce job to transpose event tracking events into a <a href="https://en.wikipedia.org/wiki/Star_schema" target="_blank">star schema</a>, to make them usable for the BI system (MS SQL Server and Microsoft Analysis Services / SSAS). <b-badge>Java</b-badge> <b-badge>Apache Hadoop</b-badge></li>
                  <li>Integration of <a href="https://deviceatlas.com/" target="_blank">DeviceAtlas</a> into the event tracking pipeline to enable analysis of player devices for all user events via Hive UDF. This data plays an important role in the company's mobile strategy and is connected to many other KPIs in the BI system. <b-badge>Java</b-badge> <b-badge>Apache Hive</b-badge></li>
                  <li>Scaled Hadoop ETL pipelines by introducing <a href="https://www.jenkins.io/" target="_blank">Jenkins</a> with the <a href="https://plugins.jenkins.io/job-dsl/" target="_blank">Job DSL plugin</a> to maintain ETL jobs as Groovy code. <b-badge>Apache Hadoop</b-badge> <b-badge>Jenkins</b-badge> <b-badge>Groovy</b-badge></li>
                  <li>Implementation of “client side tracking”, which allows end users to send events directly to the event pipeline using the <a href="https://github.com/openresty/lua-nginx-module#readme" target="_blank">NGINX Lua module</a>. The user tracking on landing pages was greatly improved with this option, which helps to optimize them even more. <b-badge>NGINX</b-badge> <b-badge>Lua</b-badge></li>
                  <li>Technical support for the data mining team to develop and integrate a <strong>machine learning</strong> model which can predict whether players will stop playing the games (“churn”) within the next 7/15/30/60 days. The model is based on the user events and uses a decision tree. The <strong>hitrate is 90-95%</strong> on average. The data is used to  counteract very effectively with CRM measures. <b-badge>Machine Learning</b-badge> <b-badge>R</b-badge></li>
                </ul>
              </div>
            </div>

            <div style="margin-bottom: 2.5rem;">
              <h6 :title="timeSince(new Date('2011-06-01'), new Date('2013-09-01'))">June 2011 – August 2013</h6>
              <div class="d-flex justify-content-between align-items-baseline overflow-auto">
                <h2>Software Developer <small class="text-nowrap" style="font-size:0.9rem; margin-left: 4px;">(Data Engineering, Backend Systems)</small></h2>
                <h3 style="margin-left: 20px;" class="text-nowrap">InnoGames GmbH</h3>
              </div>

              <div class="article-content mt-2">
                <ul>
                  <li>Implementation of a central system to track user’s premium currency in so called “user wallets”. As part of the system, a liability process for finance was built in T-SQL (MS SQL Server) and Java using a FIFO calculation. <b-badge>Java</b-badge> <b-badge>Hibernate</b-badge> <b-badge>MySQL</b-badge></li>
                  <li>Setup of the <strong>first Hadoop system</strong> as a basis for “player snapshots” using <a href="https://www.cloudera.com/products/open-source/apache-hadoop/key-cdh-components.html" target="_blank">Cloudera CDH</a>. Snapshots are created daily for more than 75 million players (with infinite history) and are used by the Analytics department to find trends in player behavior, primarily with focus on activity / retention and premium spending. All relevant information from other systems are used to create a data warehouse. Analyses are done with HQL (Hive). <strong>This is the company's first step towards Big Data.</strong> <b-badge>Apache Hadoop</b-badge> <b-badge>Apache Hive</b-badge> <b-badge>Apache Sqoop</b-badge></li>
                  <li>Design and implementation of a <strong>event tracking</strong> system, which allows games to track fine-grained user interactions for analysis. The system is designed using a <strong>fully decoupled approach to prevent failure</strong>, since games only need to write to a simple local text file. These events are asynchronously forwarded to a central gateway, which puts them into a bus for stream-processing. A Java consumer reads these events from the bus to put them into Hadoop.
                  <b-badge>Java</b-badge> <b-badge>Dropwizard (Java framework)</b-badge> <b-badge>Apache Kafka</b-badge> <b-badge>Apache Hadoop</b-badge>
                  <b-badge></b-badge>
                  </li>
                  <li>Implementation of a central system to allow players to register and log into any game using widely used single sign-on (SSO) solutions. Among others, players can use their Google, Facebook and Apple accounts to play the games. Lowering the barrier to register improved the conversion funnel significantly. <b-badge>PHP</b-badge></li>
                  <li>Implementation of a self-service tool for players that allows them to delete their accounts on their own. This reduced manual effort for community management a lot. <b-badge>PHP</b-badge> <b-badge>Symfony (PHP)</b-badge></li>
                  <li>Implementation of a tool to attribute <a href="https://en.wikipedia.org/wiki/Direct_response_television" target="_blank">DRTV</a> television marketing traffic to the respective TV spots to estimate their monetary impact. <b-badge>Java</b-badge> <b-badge>Akka</b-badge></li>
                  <li>Concept, system design and implementation of a company wide, fail-safe solution for A/B-testing in all games. <b-badge>PHP</b-badge> <b-badge>Symfony</b-badge> <b-badge>Doctrine</b-badge></li>
                  <li>Supported implementation of mobile tracking (via <a href="https://adjust.com" target="_blank">Adjust.com</a>) to attribute players on mobile devices to marketing campaigns. <b-badge>PHP</b-badge> <b-badge variant="success">Mentoring</b-badge></li>
                  <li>Consistent support for analysis of various data, development of custom <a href="https://cwiki.apache.org/confluence/display/hive/languagemanual+udf#LanguageManualUDF-CreatingCustomUDFs" target="_blank">Hive UDFs</a> (User-Defined Functions) that can be used in user queries; SQL / HQL (Hive) support for the analytics department. <b-badge variant="success">Mentoring (end users)</b-badge></li>
                  <!-- <li><b-badge variant="primary">Primary</b-badge> <b-badge variant="secondary">Secondary</b-badge> <b-badge variant="success">Success</b-badge> <b-badge variant="danger">Danger</b-badge> <b-badge variant="warning">Warning</b-badge> <b-badge variant="info">Info</b-badge> <b-badge variant="light">Light</b-badge> <b-badge variant="dark">Dark</b-badge></li> -->
                </ul>
              </div>
            </div>
          </div>

          <div class="mb-5">
            <h1>
              <span class="indent">
                <span class="material-icons material-icon-header"> work_outline </span>
                Honorary Work
              </span>
            </h1>
            <h6 :title="timeSince(new Date('2009-08-01'), new Date('2012-08-01'))">August 2009 – July 2012</h6>
            <div class="d-flex justify-content-between align-items-baseline overflow-auto">
              <h2><span>Head of Portal Development</span>, <span class="text-nowrap">System Administrator</span></h2>
              <h3 style="margin-left: 20px;" class="text-nowrap">RauteMusik e.V.</h3>
            </div>

            <div class="article-content mt-2">
              <ul>
                <li>Lead, conception and development of a revamp for the <a href="https://RauteMusik.fm" target="_blank">RauteMusik.fm</a> portal with several frontend and backend developers. Close collaboration with the CEOs. <b-badge>PHP</b-badge> <b-badge>MySQL</b-badge> <b-badge>HTML / CSS / JavaScript</b-badge></li>
                <li>Overhaul of the system architecture, introduction of virtualization, improvement of overall stability and security. <b-badge>Xen</b-badge> <b-badge>Linux</b-badge></li>
              </ul>
            </div>
          </div>

          <div class="mb-5">
            <h1>
              <span class="indent">
                <span class="material-icons material-icon-header"> work_outline </span>
                Apprenticeship
              </span>
            </h1>
            <h6 :title="timeSince(new Date('2008-08-01'), new Date('2011-07-01'))">August 2008 – June 2011</h6>
            <div class="d-flex justify-content-between align-items-baseline overflow-auto">
              <h2>Trainee Software Development <small class="text-nowrap" style="font-size:0.9rem; margin-left: 4px;">(FIAE, IHK Hamburg)</small></h2>
              <h3 style="margin-left: 20px;" class="text-nowrap">InnoGames GmbH</h3>
            </div>

            <div class="article-content mt-2">
              <ul>
                <li>Conception and development of a new player portal “InnoGames.com”, which gives players a central place to play all games that the company has to offer. It is also used to improve marketing efforts by cross-selling players among the games. <b-badge>PHP</b-badge></li>
                <li>Development of a central, <strong>highly available player database</strong> to manage all 60 million players using a MySQL master-master setup and logical horizontal partitioning (sharding). <b-badge>PHP</b-badge> <b-badge>MySQL</b-badge> <b-badge>MySQL MMM</b-badge></li>
                <li>Continuous development of internal applications for controlling, marketing and player stats. As part of this, a central event bus based on RabbitMQ is implemented to measure user activity across all games. These are the first tools to make the company data-driven. <b-badge>PHP</b-badge> <b-badge>Java</b-badge></li>
                <li>Implementation of central C2S marketing partner tracking (HTML / JavaScript pixels) and internal monitoring to detect fraudulent partners. <b-badge>PHP</b-badge> <b-badge>MySQL</b-badge></li>
                <li>Feature development and technical support for backend integrations on the games “Die Stämme”, “The West” and “Grepolis”. <b-badge>PHP</b-badge></li>
                <li>Conception and development of a system that supports frontend developers to easily setup marketing landing pages, including routing setup (CNAMEs) and one click deployment. <b-badge>PHP</b-badge></li>
                <li>Part of the 24-hours on-call duty to resolve critical hard- and software errors.</li>
              </ul>
            </div>
          </div>

          <div class="mb-5">
            <h1>
              <span class="indent">
                <span class="material-icons material-icon-header"> work_outline </span>
                Internships
              </span>
            </h1>

            <div style="margin-bottom: 2.5rem;">
              <h6 title="2 weeks">2006-10-16 – 2006-10-27</h6>

              <div class="d-flex justify-content-between align-items-baseline overflow-auto">
                <h2>IT Systems Technician</h2>
                <h3 style="margin-left: 20px;" class="text-nowrap">Klinikum Bremerhaven-Reinkenheide</h3>
              </div>

              <div class="article-content mt-2">
                <ul>
                  <li>Setting up hardware for end users, PC assembly, workstations</li>
                  <li>Troubleshooting of hard- and software problems</li>
                  <li>End user support, ensuring that everyone in the hospital can work</li>
                </ul>
              </div>
            </div>

            <div style="margin-bottom: 2.5rem;">
              <h6 title="3 weeks">2006-06-26 – 2006-07-14</h6>

              <div class="d-flex justify-content-between align-items-baseline overflow-auto">
                <h2>IT Systems Technician</h2>
                <h3 style="margin-left: 20px;" class="text-nowrap">Klinikum Bremerhaven-Reinkenheide</h3>
              </div>

              <div class="article-content mt-2" style="padding-bottom: 0.8em">
                <span class="font-italic">Same as above</span>
              </div>
            </div>
          </div>

          <!-- 16.10.2006 - 27.10.2006	12 days, two weeks
          26.06.2006 - 14.07.2006 19 days, three weeks -->

          <div class="mb-5">
            <h1>
              <span class="indent">
                <span class="material-icons material-icon-header"> translate </span>
                Languages
              </span>
            </h1>

            <div class="row">
              <div class="col-sm-6"><div style="width: 95%"><Stars :c="{name: 'German:', extra: 'Native Speaker', amount: 10}"/></div></div>
              <div class="col-sm-6"><div style="width: 95%;"><Stars :c="{name: 'English:', extra: 'Fluent', amount: 8}"/></div></div>
            </div>

            <div class="font-italic mt-3 text-center">“I prefer working in an international environment.”</div>
          </div>

          <div class="mb-5">
            <h1>
              <span class="indent">
                <span class="material-icons material-icon-header"> groups </span>
                Conferences
              </span>
            </h1>

            <div class="mb-3">Regular visitor of these conferences:</div>

            <b-row cols="1" cols-sm="2">
              <b-col><div class="asd bold"><!-- <img src="../assets/hadoop_summit.png" class="d-block mx-auto mb-3" style="width: 80%"> -->Hadoop Summit</div></b-col>
              <b-col><div class="asd bold"><!-- <img src="../assets/bbuzz.png" class="d-block mx-auto mb-3" style="max-width: 80%; max-height: 180px;"> -->Berlin Buzzwords</div></b-col>
              <b-col><div class="asd bold">code.talks (“Developer Conference”)</div></b-col>
              <b-col><div class="asd bold">PHP Unconference #PHPunconf</div></b-col>
              <b-col><div class="asd bold">FrOSCon</div></b-col>
              <b-col><div class="asd bold">Voxxed Days</div></b-col>
            </b-row>
          </div>

          <div class="mb-5">
            <h1>
              <span class="indent">
                <span class="material-icons material-icon-header"> widgets </span>
                Hard Skills
              </span>
            </h1>


            <div class="text-left">
              <!-- <div class="article-content" style="padding: 0.8em 1.1em 0.8em;"> -->
              <div>
                <h3 style="font-size: 1.2em; font-weight: 500">Software Development</h3>
                <b-badge variant="light" class="skill">Java</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Python</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">PHP</b-badge>{{ ' ' }}
                <!-- <b-badge variant="light" class="skill">Subversion</b-badge>{{ ' ' }} -->
                <b-badge variant="light" class="skill">Git</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Security</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Vagrant</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Docker</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">(REST) APIs</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Webservices</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">High Performance</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">High Concurrency</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">GraalVM</b-badge>{{ ' ' }}

                <!-- <div>Web Development</div> -->
                <b-badge variant="light" class="skill">Web Development</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Webpack</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Vue.js</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">HTML</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">CSS</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Bootstrap</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">JavaScript</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">TypeScript</b-badge>{{ ' ' }}
                <!-- <b-badge variant="light" class="skill">jQuery</b-badge>{{ ' ' }} -->

                <h3 style="font-size: 1.1em; font-weight: 500" class="mt-4">Big Data</h3>
                <b-badge variant="light" class="skill">Apache Hadoop</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Apache Kafka</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Apache Hive</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Apache Spark</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Apache Flink</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Apache Zookeeper</b-badge>{{ ' ' }}
                <!-- <b-badge variant="light" class="skill">Big Data</b-badge>{{ ' ' }} -->
                <b-badge variant="light" class="skill">SQL</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Lambda architecture</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Database development</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">MySQL</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Sharding</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">PostgreSQL</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">MapReduce</b-badge>{{ ' ' }}

                <h3 style="font-size: 1.1em; font-weight: 500" class="mt-4">Server Administration</h3>
                <b-badge variant="light" class="skill">Arch Linux</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Ubuntu</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Debian</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">DevOps</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Shell Scripting</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Bash</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">nginx</b-badge>{{ ' ' }}

                <h3 style="font-size: 1.1em; font-weight: 500" class="mt-4">Other</h3>
                <b-badge variant="light" class="skill">Kanban</b-badge>{{ ' ' }}
                <b-badge variant="light" class="skill">Automation</b-badge>{{ ' ' }}
                <!-- <b-badge variant="light" class="skill">Setup of a cross functional team</b-badge>{{ ' ' }} -->


                <!-- <b-badge variant="light" class="skill">Backend development</b-badge>{{ ' ' }} -->
                <!-- <b-badge variant="light" class="skill">XML</b-badge>{{ ' ' }} -->
                <!-- <b-badge variant="light" class="skill">Open Source</b-badge>{{ ' ' }} -->
                <!-- <b-badge variant="light" class="skill">Apache</b-badge>{{ ' ' }} -->
                <b-badge variant="light" class="skill">Mentoring</b-badge>{{ ' ' }}
              </div>
            </div>
            <div class="mt-4 text-right small font-italic">(no complete list)</div>

          </div>

          <div class="mb-5">
            <h1>
              <span class="indent">
                <span class="material-icons material-icon-header"> self_improvement </span>
                Soft Skills
              </span>
            </h1>

            <div class="text-center">
              <b-badge variant="light" class="skill">Communication</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Writing</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Documentations</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Regular Presentations <span>(various audiences)</span></b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Innovation</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Critical Thinking</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Problem Solving</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Troubleshooting</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Brainstorming</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Patience</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Research</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Organization</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Time Management</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Planning / Priorization</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Project Management</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Pair Programming</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Code Reviews</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Continuous Improvement</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Technical Leadership</b-badge>{{ ' ' }}
              <b-badge variant="light" class="skill">Knowledge Transfer</b-badge>{{ ' ' }}
            </div>
          </div>

          <div class="mb-5">
            <h1>
              <span class="indent">
                <span class="material-icons material-icon-header"> message </span>
                Feedbacks
              </span>
            </h1>

            <blockquote class="blockquote mb-5 asd" style="font-size: 100%">
              <p class="mb-0 text-center"><cite>“During his 12 years of experience as a Software Developer at
                  InnoGames, Tom developed a majority of the essential backend
                  systems that are still used today. Apart from his strong expertise
                  regarding system administration, Tom is managing the Big Data
                  landscape at InnoGames including stream and batch
                  processing.”</cite></p>
              <footer class="blockquote-footer text-right">Supervisor Feedback (2020)</footer>
            </blockquote>

            <blockquote class="blockquote mb-5 asd" style="font-size: 100%">
              <p class="mb-0 text-center"><cite>“Tom is an incredibly talented developer. The work that he has done on the PlayerDB and SelectionDB is truly impressive.”</cite></p>
              <footer class="blockquote-footer text-right">Colleague Feedback (2018)</footer>
            </blockquote>

            <blockquote class="blockquote mb-5 asd" style="font-size: 100%">
              <p class="mb-0 text-center"><cite>“Tom was again able to exceed my expectations quantity wise. The good achievement of our goals underlines this. Based on the feedback I got so far, everyone who knows Tom respects and appreciates his broad knowledge. His long experience and expertise with InnoGames specific systems, Big Data related topics and Software Engineering in general give him a good standing in the company and everyone is happy if he or she can learn from Tom.”</cite></p>
              <footer class="blockquote-footer text-right">Supervisor Feedback (2017)</footer>
            </blockquote>

            <blockquote class="blockquote mb-5 asd" style="font-size: 100%">
              <p class="mb-0 text-center"><cite>“Among other things, Tom's strength is definitely his analytical mind. He easily can analyze unknown data sets, code or processes and can spot anomalies. On the basis of his outstanding experience, he can see the big picture of projects. He does not only know how to integrate new components, he also understands the impact of changes on all levels. He is detail and quality oriented.”</cite></p>
              <footer class="blockquote-footer text-right">Supervisor Feedback (2016)</footer>
            </blockquote>
          </div>

          <!-- <hr> -->

        <!-- Todo: -->
        <!-- <li>improve skills</li> -->
        <!-- <li>amount of servers, size of hadoop cluster</li> -->
        <!-- <li>make stuff bold</li> -->
        <!-- <li>conferences images</li> -->
        <!-- <li>add more links</li> -->
        <!-- <li>change photo</li> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from './Stars.vue'

export default {
  components: {
    Stars
  },
  data() {
    return {
      competencies: [
        {name: 'IT fundamentals', amount: 10},
        {name: 'Java', amount: 9},
        {name: 'DevOps (Linux)', amount: 8},
        {name: 'Big Data / Data Engineering', amount: 8},
        {name: 'Architecture / System Design', amount: 8},
        {name: 'PHP', amount: 8},
        {name: 'Web Applications (SSR and Webpack)', amount: 7},
        {name: 'Python', amount: 7},
        {name: 'Mentoring / Consulting', amount: 7},
      ]
    }
  },
  methods: {
    timeSince(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();

      if (months <= 0) {
        return ''
      }

      if (months < 12) {
        return months + ' months'
      }

      var m = months % 12

      if (!m) {
        return Math.floor(months / 12) + ' years'
      }

      return Math.floor(months / 12) + ' years, ' + m + ' months'
    }
  }
}
</script>

<style scoped lang="scss">
.asd {
  margin: 9px 0;
  padding: 16px;

  color: #212529;
  background-color: #f8f9fa;

  line-height: 1.6;
  letter-spacing: 0.015em;

  border-radius: 0.25rem;
  text-align: center;

  &.bold {
    font-weight: 500;
  }
}

.badge.skill {
  margin: 5px 2px;
  padding: 0.4em 0.8em;
  font-size: 100%;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.015em;
}

b.light, strong.light {
    font-weight: 500;
}

b, strong {
    font-weight: 600;
}

.interests {
  ul {
    list-style-type: square;
    padding-left: 2.5em;
  }
}
li:not(:last-child) {
   margin-bottom: 8px;
}

.material-icon-header {
  color: rgb(101, 97, 118);
  font-size: 110%;
  font-weight: normal;
  vertical-align: middle;
  margin-bottom: 4px;
  width: 1.2em;
}

.right-col {
  padding-left: 2rem;
  padding-right: 2rem;
}

.left-col {
  padding-left: .5rem;
  padding-right: .5rem;
}

.article-content {
  background-color: rgb(242, 245, 247);
  border-radius: 8px;
  padding: 0.8em 1.1em 0.4em;
  line-height: 150%;

  ul {
    list-style-type: square;
    margin-bottom: 0.5em;
    padding-left: 1.5em;
  }

  p {
    margin-bottom: 0.5rem;
  }
}

h1 {
  font-size: 1.125rem;
  font-family: Lato, sans-serif;

  border-bottom: 1px solid rgb(194, 209, 217);
  font-weight: bold;
  line-height: 130%;
  letter-spacing: 1px;
  margin-top: 2rem;
  padding-bottom: 0.7em;
  margin-bottom: 1.4em;

  span.indent {
    // padding: 0 0.75rem;
  }
}

h2 {
  font-size: 1.5rem;
  font-family: Lato, sans-serif;
}

h3 {
  font-size: 1.125rem;
  color: rgb(241, 102, 94);
  line-height: 133%;
}

h6 {
  font-size: 87.5%;
  font-weight: normal;
  line-height: 133%;
  margin: 0px 0px 0.75em;
}

.wrapper {
  @media (min-width: 576px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  font-size: 14px;

  letter-spacing: 0.15px;
}

.container {
    // padding-bottom: 2rem;
}

.vcard {
  padding-top: 3rem;
  padding-bottom: 3rem;

  background-color: #656176;
  color: white;

  .user-thumb {
    margin: auto;

    height: 164px;
    width: 164px;

    outline-offset: 14px;
    position: relative;

    background-color: #e1d7cd;
    border-radius: 50%;

    .image {
      background-image: url('../assets/me.jpeg');
      background-position: 10px 0px;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;

      border-radius: 50%;
    }

    &::before, &::after {
      border-bottom: 10px solid white;
      border-top: 10px solid white;
      content: "";
      transform: rotate(-22.5deg);
      z-index: 1;
      position: absolute;
      left: 47px;
      top: -3px;
      height: 170.5px;
      width: 70.5px;
    }

    &::after {
      transform: rotate(22.5deg);
    }

    .overlay {
      display: block;

      &::before, &::after {
        border-left: 10px solid white;
        border-right: 10px solid white;
        content: "";
        transform: rotate( -22.5deg );
        position: absolute;
        left: -3px;
        top: 47px;
        height: 70.5px;
        width: 170.5px;
      }

      &::after {
        transform: rotate(22.5deg);
      }
    }
  }

  img {
    margin: auto;
    text-align: center;

    width: 164px;
  }

  .my-name {
    font-family: Lato;
    font-weight: bold;
    font-size: 2.45rem;
  }

  .info {
    font-size: 1.1rem;
  }
}
</style>
